import * as SentrySvelte from '@sentry/svelte'
import { PUBLIC_LOGROCKET_APP_ID } from '$env/static/public'
import { basicSentryConfig, captureMessage } from '$lib/utilities/errorTrackingTools.js'
import LogRocket from 'logrocket'
import { dev } from '$app/environment'
import SiruAxios from '$lib/SiruAxios'
import {
    alerts,
    allowance,
    authentication,
    fingerprint,
    depositables,
    fetching,
    wallet,
    history,
    botDetection,
    hasSuccessfulPurchase,
} from '$lib/stores'
import { get } from 'svelte/store'
import { browserTracingIntegration } from '@sentry/svelte'
import type { AxiosError, AxiosResponse } from 'axios'
import type { HandleClientError } from '@sveltejs/kit'
import type { InitialData } from '$lib/types'

// Add catch-all error-handler to axios
SiruAxios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
        if (error.config?.useGlobalErrorHandler === false) {
            return Promise.reject(error)
        }

        if (error.response?.status === 401) {
            if (get(authentication).loggedIn) {
                authentication.unAuthenticate()
                alerts.add('danger', 'error.http_unauthorized')
                return
            }
        } else if (error.response?.status === 403) {
            alerts.add('danger', 'error.http_forbidden')
        } else if (error.response?.status === 429) {
            captureMessage('Rate limiter is hit when calling a backend api.', { path: error.config?.url })
            alerts.add('danger', 'error.http_too_many_requests')
        } else if (error.response?.status !== 400) {
            alerts.add('danger', 'error.unknown_error')
        }

        return Promise.reject(error)
    },
)

if (!dev) {
    // Initialize our error trackers
    window.lrPromise.then(() => LogRocket.init(PUBLIC_LOGROCKET_APP_ID))

    SentrySvelte.init({
        ...basicSentryConfig,
        integrations: [browserTracingIntegration()],
    })

    SentrySvelte.setTag('svelteKit', 'browser')
}

authentication.subscribe(async (auth) => {
    if (auth.loggedIn) {
        // Fetch initial user data
        fetching.startFetching('initialData')
        const { data } = await SiruAxios.get<InitialData>(`mysiru/rest/initial`)
        wallet.set(data.wallet)
        depositables.set(data.depositMethods)
        fetching.cancelFetching('initialData')
        hasSuccessfulPurchase.set(data.hasSuccessfulPurchase)

        // Immediately start fetching allowance
        const fetchingStore = get(fetching)
        if (!fetchingStore.allowance?.fetched && !fetchingStore.allowance?.fetching) {
            fetching.startFetching('allowance')
            allowance
                .fetch()
                .then(() => {
                    fetching.setFetched('allowance')
                })
                .finally(() => {
                    fetching.cancelFetching('allowance')
                })
        }
    } else {
        allowance.reset()
        depositables.reset()
        wallet.reset()
        history.reset()
        fetching.reset()
    }
})

// This will catch errors in load functions from +page.ts files
export const handleError: HandleClientError = ({ error, event }) => {
    SentrySvelte.captureException(error, { contexts: { sveltekit: { event } } })

    return {
        message: error.message,
    }
}

// Check for bots and get fingerprint
botDetection.checkForBot()
fingerprint.get()